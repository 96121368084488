import { Ticket } from '../ticketing/ticketing.model';

export class Notification {
  eventId: string;

  message: string;

  tickets: Ticket[];

  buttons?: {
    href: string;

    text: string;
  }[];

  constructor() {
    this.tickets = [];
    this.buttons = [];
  }
}
