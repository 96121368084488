<template>
  <v-app id="umanizeApp" v-bind:class="{ admin: inAdmin }">
    <layout-header @open-chat-users="toggleDisplay()" v-if="isLoggedIn" />

    <div class="main" v-bind:class="{ main__fullwidth: fullWidth }">
      <template v-if="menuIsVisible">
        <div class="main__main-menu" :class="{ 'main__main-menu--hidden': displayMenu }">
          <div class="main-menu__container">
            <v-sheet
              @click="openDisplayMenu()"
              class="container__pull-me"
              :class="{ 'container__pull-me--opened': displayMenu }"
              outlined
            >
              <v-icon v-if="!displayMenu" color="primaryBackground">mdi-home</v-icon>
            </v-sheet>
          </div>
        </div>

        <MainMenu
          :isOpen="displayMenu"
          @close-menu="closeDisplayMenu()"
          @change="toggleDisplayMenu"
        />
      </template>

      <router-view />
    </div>

    <div
      v-if="hasChatUsers"
      class="hidden-md-and-down chat-users"
      :class="{ 'chat-users--opened': displayed }"
    >
      <div class="chat-users__container">
        <chat-users
          :user="loggedInUser"
          :chats-users="chatsDisplay"
          @open-chat="openChat"
          @close-chat-users="toggleDisplay()"
          @update-search="updateSearch"
        />
      </div>
    </div>

    <div id="chats-list" v-if="hasOpenedChats" class="chats">
      <chats :openedChats="openedChats" :user="loggedInUser" @close-chat="closeChat" />
    </div>

    <v-dialog
      class="hidden-lg-and-up"
      v-model="displayed"
      v-if="!isCheckingSession && isMobile"
      fullscreen
    >
      <chat-users
        v-if="!displayChat"
        :user="loggedInUser"
        :chats-users="chatsDisplay"
        @open-chat="showChatForMobile"
        @close-chat-users="toggleDisplay()"
        @update-search="updateSearch"
      />

      <chats v-else :openedChats="openedChats" :user="loggedInUser" @close-chat="closeChat" />
    </v-dialog>

    <v-dialog
      content-class="v-dialog--overlay"
      width="800"
      v-model="hasNotification"
      v-if="hasNotification"
      persistent
    >
      <v-card>
        <v-card-actions class="notification__header">
          <v-btn icon @click="closeNotification"><v-icon>mdi-close</v-icon> </v-btn>
        </v-card-actions>
        <v-card-text>
          <div
            class="black--text ql-editor"
            data-gramm="false"
            spellcheck="false"
            v-html="notification.message"
          ></div>
        </v-card-text>
        <v-divider></v-divider>
        <div class="notification__actions">
          <v-card-actions v-for="(button, index) in notification.buttons" :key="index">
            <v-btn @click="redirect(button.href)" :color="accentColor" :style="btnTextColor" link>
              {{ button.text }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <snackbar :isSnackbarOpened="opened" :message="message" @close="closeSnackbar" />
    <zendesk :isVisible="!displayed || !isMobile" :lang="this.$root.$i18n.locale" />

    <layout-footer v-if="!noFooter && isLoggedIn" />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import LayoutHeader from '@/components/layout/header/LayoutHeader.vue';
import LayoutFooter from '@/components/layout/footer/LayoutFooter.vue';
import Snackbar from '@/components/snackbar/Snackbar.vue';
import ChatUsers from '@/components/chat-users/ChatUsers.vue';
import Chats from '@/components/chats/Chats.vue';
import MainMenu from '@/components/layout/main-menu/MainMenu.vue';
import Zendesk from '@/components/zendesk/Zendesk.vue';

import WindowUtil from '@/helpers/window/window.helper';

import { MESSAGE_MODULE, CLOSE_MESSAGE } from '@/stores/shared/actions/message/message.actions';
import { SHARED_AUTH_MODULE, SESSION_VALIDITY } from '@/stores/shared/actions/auth/auth.actions';
import {
  APP_CHATS_MODULE,
  CLOSE_CHAT,
  OPEN_CHAT,
  GET_CHATS,
  DISPLAY_CHATS,
  HIDE_CHATS,
  UPDATE_SEARCH,
} from '@/stores/umanize-app/actions/chats/chats.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import {
  CLOSE_NOTIFICATION,
  APP_NOTIFICATION_MODULE,
} from '@/stores/umanize-app/actions/notification/app-notification.actions';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_CUSTOM_UI_MODULE } from '@/stores/umanize-app/actions/custom-ui/app-custom-ui.actions';
import {
  APP_MENU_MODULE,
  CLEAR_SEARCH,
  SET_DISPLAY_MENU,
  CLEAR_ACTIONS_SHOWN,
} from './stores/umanize-app/actions/menu/menu.actions';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    Snackbar,
    ChatUsers,
    Chats,
    MainMenu,
    Zendesk,
  },
  data: () => ({
    fullWidth: false,
    adverts: null,
    noFooter: false,
    users: [],
    dialogOpened: false,
    displayChat: false,
    notificationOpened: false,
  }),
  computed: {
    ...mapGetters(SHARED_AUTH_MODULE, ['isCheckingSession', 'isLoggedIn']),
    ...mapGetters(MESSAGE_MODULE, ['message', 'opened']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    ...mapGetters(APP_NOTIFICATION_MODULE, ['notification', 'hasNotification']),
    ...mapGetters(APP_EVENT_MODULE, ['event', 'eventIsLoaded']),
    ...mapGetters(APP_CHATS_MODULE, [
      'chats',
      'openedChats',
      'chatsDisplay',
      'chats',
      'displayed',
      'hasGlobalUnread',
    ]),
    ...mapGetters(APP_MENU_MODULE, ['displayMenu']),
    ...mapGetters(APP_CUSTOM_UI_MODULE, ['customUi']),
    ...mapGetters(APP_EVENT_MODULE, ['unityId']),
    hasChatUsers() {
      return this.loggedInUser && !!this.chats?.length;
    },
    hasOpenedChats() {
      return this.displayed && this.loggedInUser && !!this.openedChats?.length;
    },
    inAdmin() {
      return (
        this.$route.meta.title === 'menu.adminHome' ||
        this.$route.meta.title === 'menu.admin' ||
        this.$route.path.includes('admin')
      );
    },
    hasEvent() {
      return !!this.event && !!this.$route.params?.eventId;
    },
    btnTextColor() {
      return `color: ${this.customUi?.btnTextColor || '#FFFFFF'}`;
    },
    accentColor() {
      return this.customUi?.btnColor || '#00B5A8';
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    menuIsVisible() {
      const noMenuRoutes = ['Buy', 'Summary', 'Payment', 'Confirmation'];
      return (
        this.isLoggedIn &&
        this.hasEvent &&
        !this.inAdmin &&
        !noMenuRoutes.includes(this.$route.name)
      );
    },
  },
  methods: {
    ...mapActions(MESSAGE_MODULE, [CLOSE_MESSAGE]),
    ...mapActions(APP_NOTIFICATION_MODULE, [CLOSE_NOTIFICATION]),
    ...mapActions(APP_CHATS_MODULE, [
      GET_CHATS,
      OPEN_CHAT,
      CLOSE_CHAT,
      HIDE_CHATS,
      DISPLAY_CHATS,
      UPDATE_SEARCH,
    ]),
    ...mapActions(SHARED_AUTH_MODULE, [SESSION_VALIDITY]),
    ...mapActions(APP_MENU_MODULE, [SET_DISPLAY_MENU, CLEAR_SEARCH, CLEAR_ACTIONS_SHOWN]),
    closeNotification() {
      this[CLOSE_NOTIFICATION]();
    },
    closeSnackbar() {
      this[CLOSE_MESSAGE]();
    },
    toggleDisplay() {
      if (!this.displayed) {
        this[DISPLAY_CHATS]();
      } else {
        this[HIDE_CHATS]();
        WindowUtil.giveFocusBackToShowroomIfExists(this.unityId);
      }
    },
    openChat(params) {
      this[OPEN_CHAT](params);
    },
    redirect(url) {
      window.open(url, '_blank');
    },
    closeChat(id) {
      this[CLOSE_CHAT](id);
      this.displayChat = false;
    },
    updateSearch(value) {
      this[UPDATE_SEARCH](value);
    },
    showChatForMobile(params) {
      this[OPEN_CHAT](params);
      this.displayChat = true;
    },
    openDisplayMenu() {
      this[SET_DISPLAY_MENU](true);
    },
    closeDisplayMenu() {
      this[SET_DISPLAY_MENU](false);
      this[CLEAR_SEARCH]();
      this[CLEAR_ACTIONS_SHOWN]();
    },
    toggleDisplayMenu(value) {
      if (this.displayMenu !== value) {
        this[SET_DISPLAY_MENU](value);
      }
    },
  },
  async mounted() {
    await this[SESSION_VALIDITY]();
    if (this.loggedInUser) {
      this[GET_CHATS]();
    }
  },
  watch: {
    $route({ meta }) {
      this.fullWidth = meta.fullWidth || false;
      this.adverts = meta.adverts || null;
      this.noFooter = meta.noFooter || false;
    },
  },
};
</script>

<style lang="scss">
@import '@styles/core/variables';

#umanizeApp {
  font-family: $base-font-family;
  font-size: $default-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--v-primaryFont-base);

  min-height: 100vh;
  overflow: hidden;

  h1,
  h2,
  h3,
  h4,
  h5,
  .alternate-font-family {
    /* KeepCalm-Medium */
    font-family: 'Maven Pro', sans-serif !important;
  }
}

html {
  min-width: 100% !important;
  min-height: 100% !important;
}

body {
  min-width: 100vw !important;
  min-height: 100% !important;
  overflow: auto !important;

  margin: 0;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: transparent;
    color: var(--v-primaryFont-base);
    -webkit-text-fill-color: var(--v-primaryFont-base);
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.main {
  margin: $header-mobile-height auto auto auto;

  @include breakpoint(medium) {
    margin: $header-height auto auto auto;
  }

  width: 100vw;

  &__main-menu {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: $z-index-chat;

    visibility: visible;

    animation: fadeIn 1s;

    &--hidden {
      visibility: hidden;
      animation: fadeOut 0.5s;
    }
  }
}

.container {
  &__pull-me {
    @include pull-me();

    margin-top: rem(46px);
  }
}

.notification {
  &__actions {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__header {
    display: flex;
    justify-content: flex-end;
  }
}

.admin {
  background-color: var(--v-adminBackground-base) !important;
}

.admin {
  background-color: var(--v-adminBackground-base) !important;
}

.chats {
  height: 100%;
  width: 100%;
  background: transparent;
}

@keyframes fadeOut {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}

@keyframes fadeIn {
  0% {
    visibility: hidden;
  }
  20% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

@include breakpoint(small) {
  $chat-panel-size: 20rem;
  $chat-space: 0.5rem;
  $help-icon: 70px;

  .main {
    width: 80vw;

    &__fullwidth {
      width: 100vw;
    }
  }

  .chat-users {
    max-height: 350px;
    width: $chat-panel-size;

    position: fixed;
    top: calc(#{$header-height} + 1.5rem);
    right: -20rem;
    z-index: $z-index-chat;

    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;

    border: 1px solid var(--v-primaryFont-base);
    border-radius: 0.6rem;

    &--opened {
      right: $chat-space;
    }

    &__container {
      position: relative;
      height: 350px;
    }
  }

  .chat-users-container {
    &__pull-me {
      display: flex;
      align-items: center;
      padding: 1rem;

      position: absolute;
      top: 1rem;
      left: -3rem;
      height: 4rem;
      width: 3rem;

      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      border-left-color: var(--v-primary-base) !important;
      border-top-color: var(--v-primary-base) !important;
      border-bottom-color: var(--v-primary-base) !important;
      border-right-color: transparent !important;

      cursor: pointer;
    }
  }

  .chats {
    position: fixed;
    bottom: $chat-space;
    right: calc(#{$chat-space} * 2 + #{$help-icon});
    width: fit-content;
    height: 24rem;
    background: transparent;
    z-index: $z-index-chat;
    border-radius: 0.5rem;
  }

  .container {
    &__pull-me {
      margin-top: rem(57px);
    }
  }
}
</style>
